import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {
    Card,
    Container,
    Row,
    Svg,
    ThemeType, useToaster
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";
import {useParams} from "react-router";
import {routeCustomers, routeParamsSubscriptions} from "../../routes";
import {CustomerTabContent} from "../../customers/components/customer-tab-content";
import {usePaymentStore} from "../../params/payment-store";
import {FRONT_URI, ROOT_PATH} from "../../config/config";
import {CustomerSubscriptionsList} from "../../customers/components/customer-subscriptions-list";
import {useAuthStore} from "../../providers/sso/auth-provider";
import {useCustomersStore} from "../../customers/customers-store";
import {Customer} from "../../customers/customers-types";
import {Error404} from "../../commons/components/404-error";
import {CustomerSubscriptionPayload} from "../../params/payment-types";

const H2 = styled.h2`
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  color: #414141;
  font-family: 'Raleway', sans-serif;
`

export const CustomerEditPage: FC = () => {
    const theme = useTheme() as ThemeType;
    const toast = useToaster();
    const {locale, ...intl} = useIntl();
    const {id} = useParams();
    const {codeBouton} = useAuthStore();
    const {
        fetchSubscriberByCustomer,
        subscriber,
        fetchAllSubscriptions,
        subscriptionsList,
        sendSubscriptionProposal,
        cancelSubscriberSubscription
    } = usePaymentStore();
    const {getCustomer} = useCustomersStore();
    const [customer, setCustomer] = useState<Customer>();
    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const basePath = ROOT_PATH.length > 1 ? ROOT_PATH : '';

    useEffect(() => {
        setLoading(true);
        if (!id) {
            window.location.pathname = basePath + routeParamsSubscriptions;
            return;
        }
        Promise.all([fetchSubscriberByCustomer(id), fetchAllSubscriptions(), getCustomer(id)])
            .then(res => setCustomer(res[2]))
            .catch(e => {
                console.error(e);
                setError(true)
            })
            .finally(() => setLoading(false))
    }, [basePath, fetchAllSubscriptions, fetchSubscriberByCustomer, getCustomer, id])

    const onSubscriptionProposalSend = useCallback(async (form?: CustomerSubscriptionPayload) => {
        if (!form) {
            return;
        }
        setSubmitLoading(true);
        try {
            await sendSubscriptionProposal(form)
            toast(intl.formatMessage({
                id: 'customerSubscriptionProposalSendToastMessageSuccess',
                defaultMessage: 'Un email a été envoyé à votre client pour lui proposer l\'abonnement sélectionné'
            }))

        } catch (e) {
            console.error(e)
            toast(intl.formatMessage({
                id: 'customerSubscriptionProposalSendToastMessageError',
                defaultMessage: 'Une erreur est survenue, la proposition d\'abonnement n\'a pas pu être envoyée. Merci de réessayer ultérieurement.'
            }))
        } finally {
            setSubmitLoading(false)
        }
    }, [intl, sendSubscriptionProposal, toast])

    const onSubscriptionCancel = useCallback(async (subscriberId: string, subscriptionId: string, customerId: string) => {
        setCancelSubscriptionLoading(true)
        try {
            await cancelSubscriberSubscription(subscriberId, subscriptionId)
            await fetchSubscriberByCustomer(customerId)
        } catch (e) {
            console.error(e);
            toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
        } finally {
            setCancelSubscriptionLoading(false);
            setOpen(false);
        }
    }, [cancelSubscriberSubscription, fetchSubscriberByCustomer, intl, toast])

    const tabs: { title: string, pathname?: string, content: JSX.Element | null }[] = useMemo(() => {
        return [
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleInfo', defaultMessage: 'Informations'}),
                pathname: `client/addclient/${id}/infos`,
                content: null
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleBooking', defaultMessage: 'Réservation(s)'}),
                pathname: `client/addclient/${id}/appointments`,
                content: null
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleTimeline', defaultMessage: 'Historique'}),
                pathname: `client/addclient/${id}/history`,
                content: null
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleGiftcards', defaultMessage: 'Bons cadeaux'}),
                pathname: `client/addclient/${id}/giftcards`,
                content: null
            },
            {
                title: intl.formatMessage({id: 'navSettingsSubscription', defaultMessage: 'Abonnements'}),
                content: <CustomerSubscriptionsList list={subscriber?.subscriptions}
                                                    onSubscriptionProposalSend={onSubscriptionProposalSend}
                                                    onSubscriptionCancel={(subscriptionId) => onSubscriptionCancel(subscriber?.id||'', subscriptionId, customer?.id||id||'')}
                                                    dialogLoading={cancelSubscriptionLoading}
                                                    loading={submitLoading}
                                                    frontSubscribeBaseUri={`${FRONT_URI}/${codeBouton}/${locale}/subscription/confirmation`}
                                                    firstName={customer?.firstName || ''}
                                                    email={customer?.email || ''}
                                                    lastName={customer?.lastName || ''}
                                                    phone={customer?.phone || ''}
                                                    open={open}
                                                    onClose={() => setOpen(false)}
                                                    onOpenClick={() => setOpen(true)}
                                                    subscriptionsList={subscriptionsList}/>
            }
        ]
    }, [
        cancelSubscriptionLoading,
        codeBouton,
        customer?.email,
        customer?.firstName,
        customer?.lastName,
        customer?.phone,
        id, intl,
        locale,
        onSubscriptionCancel,
        onSubscriptionProposalSend,
        submitLoading,
        subscriber?.subscriptions,
        subscriptionsList,
        customer?.id,
        subscriber?.id,
        open
    ]);

    return <PageWrapper isLoading={loading}>
        {!error ? <Container size={'lg'}>
            <Card>
                <Row justifyContent={'space-between'} alignItems={'center'} style={{padding: '20px 16px 30px'}}>
                    <H2>
                        <FormattedMessage id={'CustomerEditPageEditTitle'} defaultMessage={'Fiche client'}/>
                    </H2>
                    <Svg icon={'cross'} fill={theme.colors.secondary} width={28} height={28}
                         style={{cursor: 'pointer'}}
                         onClick={() => window.location.pathname = basePath + routeCustomers}/>
                </Row>
                <CustomerTabContent tabs={tabs} basePath={basePath}/>
            </Card>
        </Container> : <Error404/>}
    </PageWrapper>
}