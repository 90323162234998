import React, {FC} from "react";
import {Col, Dialog, SpanBody1, SpanCaption1, SpanSubtitle1, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {ProposalButton} from "./customer-subscriptions-list";
import {FormattedMessage, useIntl} from "react-intl";
import {DialogFooter} from "../../commons/components/dialog-footer";
import {canceled, SubscriberSubscriptionStatus} from "../../params/payment-types";

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey80};
  padding: 8px 0;
  gap: 16px;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
`

interface CustomerSubscriptionCardProps {
    name: string;
    onReadOnlyClick: () => void;
    onSubscriptionCancel: () => void;
    dialogLoading: boolean;
    open: boolean;
    onClose: () => void;
    onOpenClick: () => void;
    status: SubscriberSubscriptionStatus
}

export const CustomerSubscriptionCard: FC<CustomerSubscriptionCardProps> = ({
                                                                                name,
                                                                                onReadOnlyClick,
                                                                                onSubscriptionCancel,
                                                                                dialogLoading,
                                                                                onClose,
                                                                                open,
                                                                                onOpenClick,
                                                                                status
                                                                            }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <>
        <Wrapper>
            <Col columns={[5]}>
                <SpanSubtitle1>{name}</SpanSubtitle1>
            </Col>
            <Col style={{padding: 0}}>
                <SquareButton icon={'eye'} onClick={onReadOnlyClick}/>
            </Col>
            <Col>
                {status === canceled
                    ? <SpanCaption1>
                        <FormattedMessage id={'canceledSubscription'} defaultMessage={'Abonnement annulé'} />
                    </SpanCaption1>
                    : <ProposalButton color={theme.colors.danger} onClick={onOpenClick}>
                    <FormattedMessage id={'paramsSubscriptionCardStopButtonlabel'}
                                      defaultMessage={'Arrêter le renouvellement'}/>
                </ProposalButton>}
            </Col>
        </Wrapper>
        <Dialog onClose={onClose}
                show={open}
                size={'md'}
                title={intl.formatMessage({
                    id: 'paramsSubscriptionCardStopButtonlabel',
                    defaultMessage: 'Arrêter le renouvellement'
                })}
                footer={<DialogFooter onCancelClick={onClose}
                                      loading={dialogLoading}
                                      onApproveClick={onSubscriptionCancel}/>}>
            <SpanBody1>
                <FormattedMessage
                    id={'customerSubscriptionCancelModalText'}
                    defaultMessage={'Etes-vous sûr(e) de vouloir arrêter le renouvellement de cet abonnement ? {br} Un email sera envoyé à votre client pour le ' +
                        'prevenir du non-renouvellement de cette formule d\'abonnement.'}
                    values={{br: <br/>}}/>
            </SpanBody1>
        </Dialog>
    </>
}