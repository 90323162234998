import {FC, useCallback, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";
import {BaseButton, Col, Row, SelectOption, SpanCaption1, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {lighten} from "polished";
import {
    CustomerSubscriptionPayload,
    SubscriberSubscription,
    Subscription,
    SubscriptionPlan
} from "../../params/payment-types";
import {CustomerSubscriptionCard} from "./customer-subscription-card";
import {ROOT_PATH} from "../../config/config";
import {routeParamsSubscriptions} from "../../routes";
import {CustomerSubscriptionForm} from "./customer-subscription-form";

const Container = styled.div`
  padding: 14px 0 0;
`
export const ProposalButton = styled(BaseButton)<{ color: string }>`
  color: ${({color}) => color};
  font-family: "Open Sans", sans-serif;
  font-size: 14.718px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.182px;
  text-decoration-line: underline;

  :hover {
    color: ${({color}) => lighten(0.05, color)}
  }
`
const ListTitle = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: ${({theme}) => theme.colors.dark};
  margin: 0;
`

interface CustomerSubscriptionsListProps {
    list?: SubscriberSubscription[];
    subscriptionsList: Subscription[];
    frontSubscribeBaseUri: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    onSubscriptionProposalSend: (form?: CustomerSubscriptionPayload) => void;
    loading: boolean;
    onSubscriptionCancel: (subscriptionId: string) => void;
    dialogLoading: boolean;
    onClose: () => void;
    onOpenClick: () => void;
    open: boolean
}

export const CustomerSubscriptionsList: FC<CustomerSubscriptionsListProps> = ({
                                                                                  list,
                                                                                  subscriptionsList,
                                                                                  frontSubscribeBaseUri,
                                                                                  email,
                                                                                  firstName,
                                                                                  lastName,
                                                                                  phone,
                                                                                  onSubscriptionProposalSend,
                                                                                  loading,
                                                                                  onSubscriptionCancel,
                                                                                  dialogLoading,
                                                                                  onClose,
                                                                                  open,
                                                                                  onOpenClick
                                                                              }) => {
    const theme = useTheme() as ThemeType;
    const {locale, ...intl} = useIntl();
    const [form, setForm] = useState<CustomerSubscriptionPayload>();
    const [selectedSubscription, setSelectedSubscription] = useState<SubscriberSubscription>();

    const getFrequencyTitle = useCallback((number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({id: 'annual', defaultMessage: 'annuel'})
            case 3:
                return intl.formatMessage({id: 'quarterly', defaultMessage: 'trimestriel'})
            case 1:
                return intl.formatMessage({id: 'monthly', defaultMessage: 'mensuel'})
            default:
                return ''
        }
    }, [intl])

    const subscriptionsOptions: SelectOption<Subscription>[] = useMemo(() => subscriptionsList.map(subscription => {
        return {value: subscription, label: subscription.name, uid: subscription.id}
    }), [subscriptionsList])

    const plansOptions: SelectOption<SubscriptionPlan>[] | undefined = useMemo(() => {
        const list = subscriptionsList.find(el => el.id === form?.subscriptionFormulaId);
        if (list) {
            return list.plans.filter(el => el.active).map(plan => {
                return {
                    value: plan,
                    label: `${intl.formatNumber(plan.price || 0, {
                        style: 'currency',
                        currency: list.currency,
                        minimumFractionDigits: (plan.price || 0) % 1 === 0 ? 0 : ((plan.price || 0) % 1).toString().length <= 3 ? 1 : 2
                    })} (${getFrequencyTitle(plan.numberOfMonths)})`
                }
            })
        } else {
            return undefined
        }
    }, [form?.subscriptionFormulaId, getFrequencyTitle, intl, subscriptionsList])

    const onProposeSubscriptionClick = () => {
        if (subscriptionsList.length === 0) {
            const basePath = ROOT_PATH.length > 1 ? ROOT_PATH : '';
            window.location.pathname = basePath + routeParamsSubscriptions;
        } else {
            const defaultForm: CustomerSubscriptionPayload = {
                subscriptionFormulaId: subscriptionsList[0].id,
                frontSubscribeBaseUri: `${frontSubscribeBaseUri}/${subscriptionsList[0].id}/${subscriptionsList[0].plans[0].id}`,
                locale: subscriptionsList[0].locale || locale,
                planId: subscriptionsList[0].plans[0].id,
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone
            }
            setForm(defaultForm)
        }
    }

    return <Container>
        {!form && !selectedSubscription
            ? <>
                <ProposalButton color={theme.colors.primary} onClick={onProposeSubscriptionClick}>
                    <FormattedMessage id={'customerSubscriptionNewProposalLabel'}
                                      defaultMessage={'Proposer un nouvel abonnement'}/>
                </ProposalButton>
                <Row direction={'column'} style={{gap: '16px', marginTop: '24px'}}>
                    <Col>
                        <ListTitle>
                            <FormattedMessage id={'customerSubscriptionListTitle'}
                                              defaultMessage={'Abonnements en cours'}/>
                        </ListTitle>
                    </Col>
                    <Col columns={[12, 12, 7]}>
                        {list && list.length > 0
                            ? list.map(subscription =>
                                <CustomerSubscriptionCard key={subscription.id}
                                                          name={subscription.name}
                                                          open={open}
                                                          onClose={onClose}
                                                          onOpenClick={onOpenClick}
                                                          status={subscription.status}
                                                          onSubscriptionCancel={() => onSubscriptionCancel(subscription.id)}
                                                          dialogLoading={dialogLoading}
                                                          onReadOnlyClick={() => setSelectedSubscription(subscription)}/>)
                            : <SpanCaption1>
                                <FormattedMessage id={'customerSubscriptionListEmpty'}
                                                  defaultMessage={'Aucun abonnement en cours'}/>
                            </SpanCaption1>}
                    </Col>
                </Row>
            </>
            : <CustomerSubscriptionForm
                form={form} setForm={setForm}
                selectedSubscription={selectedSubscription}
                subscriptionsOptions={subscriptionsOptions}
                plansOptions={plansOptions}
                loading={loading}
                getFrequencyTitle={getFrequencyTitle}
                frontSubscribeBaseUri={frontSubscribeBaseUri}
                onCancelClick={() => {
                    setForm(undefined);
                    setSelectedSubscription(undefined);
                }}
                onSubmit={() => onSubscriptionProposalSend(form)}/>}
    </Container>
}